






























import Vue from 'vue'
import Logo from '@/components/Logo.vue'
import LogoVariant from '@/utils/enum-logo-variant'
import { isValue } from '@/utils/helpers'
import { getRedirectURL } from './Login.vue'

export default Vue.extend({
  components: {
    Logo,
  },
  computed: {
    _LogoVariant: () => LogoVariant,
    availableKitchens(): { name: string; id: string }[] {
      const kitchens = (this.$accessor.auth.kitchenScopes ?? [])
        .map(({ scope_id }) => scope_id)
        .filter(isValue)
        .map(
          (kitchenId) =>
            this.$accessor.cache.kitchen(kitchenId) || {
              _id: kitchenId,
              name: '(inconnue)',
            }
        )
        .map(({ name, _id }) => ({ name, id: _id }))

      // Sort kitchens alphabetically
      kitchens.sort((a, b) => a.name.localeCompare(b.name))
      return kitchens
    },
  },
  async created() {
    await Vue.$accessor.cache.updateKitchens()
  },
  methods: {
    selectKitchen(kitchenId: string) {
      this.$accessor.settings.setKitchenSelection(kitchenId)
      this.$router.push(getRedirectURL(this.$router.currentRoute))
    },
  },
})
